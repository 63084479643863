<template>
    <div>
        <!-- Main Content -->
        <div class="container mx-auto p-6">
            <h1 class="text-2xl font-bold mb-6 text-center">Precious metal karat price calculator</h1>

            <div class="flex flex-wrap lg:flex-nowrap">
                <div class="w-full lg:flex-grow lg:flex-grow-0 lg:flex-shrink-0 lg:w-1/3 lg:basis-1/3">
                    <div class="mb-4">
                        <div class="grid grid-cols-1 gap-4 p-6 bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg shadow-lg text-gray-800">
                            <div class="text-md font-semibold">
                                Current Spot Prices
                            </div>

                            <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300" @click="setMetal('gold')">
                                <p class="text-md font-semibold text-amber-400">Gold</p>

                                <div class="relative overflow-x-auto">
                                    <table class="w-full text-sm text-left rtl:text-right text-gray-600 dark:text-gray-600">
                                        <tr>
                                            <td class="font-semibold w-1/2">EUR/KG</td>
                                            <td>{{ formatCurrency(metalInformation['XAU']?.price) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-semibold">EUR/OZ</td>
                                            <td>{{ formatCurrency(metalInformation['XAU']?.priceOz) }}</td>
                                        </tr>
                                    </table>
                                </div>

                                <small class="text-xs text-gray-600 flex mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 mr-1">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    {{ metalInformation['XAG']?.ts ?? '-' }}
                                </small>
                            </div>
                            <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300" @click="setMetal('silver')">
                                <p class="text-md font-semibold text-stone-400">Silver</p>

                                <div class="relative overflow-x-auto">
                                    <table class="w-full text-sm text-left rtl:text-right text-gray-600 dark:text-gray-600">
                                        <tr>
                                            <td class="font-semibold w-1/2">EUR/KG</td>
                                            <td>{{ formatCurrency(metalInformation['XAG']?.price) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-semibold">EUR/OZ</td>
                                            <td>{{ formatCurrency(metalInformation['XAG']?.priceOz) }}</td>
                                        </tr>
                                    </table>
                                </div>

                                <small class="text-xs text-gray-600 flex mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 mr-1">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    {{ metalInformation['XAG']?.ts ?? '-' }}
                                </small>
                            </div>

                            <div class="text-xs italic">
                                No rights can be derived from the exchange rates. These are loaded from external sources over which we have no influence.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full lg:w-2/3 lg:flex-grow lg:flex-shrink-0 lg:basis-2/3  mb-6 lg:mb-0 lg:pl-6">
                    <div class="bg-white p-6 shadow-md rounded-lg mb-6">
                        <div>
                            <div class="text-md font-semibold">Select metal</div>

                            <div class="inline-flex rounded-md shadow-xs" role="group">
                                <button @click="setMetal('gold')" type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                                    Gold
                                </button>
                                <button @click="setMetal('silver')" type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                                    Silver
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white p-6 shadow-md rounded-lg">
                        <template v-if="selectedMetal === 'gold'">
                            <h2 class="mb-4 font-bold">Gold karat calculator</h2>

                            <div class="mb-4">
                                <label for="goldPrice" class="block text-gray-700 mb-1">Price (kg):</label>
                                <div class="flex items-center gap-2">
                                    <input
                                        id="goldPrice"
                                        type="number"
                                        v-model.number="goldPrice"
                                        class="flex-1 px-3 py-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                        required
                                    />

                                    <!-- Tooltip Wrapper -->
                                    <div class="relative group">
                                        <button
                                            type="button"
                                            @click="loadCurrentMetalPrice('XAU')"
                                            class="p-2 rounded-md border bg-gray-100 text-gray-600 hover:bg-gray-200 transition"
                                            aria-label="Reset to default spot price"
                                        >
                                            <!-- SVG Icon -->
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-4 w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                            >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v6h6M20 20v-6h-6M4 10a9 9 0 0115.9-2.6M20 14a9 9 0 01-15.9 2.6" />
                                            </svg>
                                        </button>

                                        <!-- Tooltip -->
                                        <div class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 w-max bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition pointer-events-none z-10">
                                            Reset to current spot price
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="mb-4">
                                <label for="goldWeight" class="block text-gray-700">Karat:</label>

                                <select v-model="goldKarat" class="w-full px-3 py-2 border rounded" required>
                                    <option value="1000">999+ - 24K Gold</option>
                                    <option value="958">958+ - 23K Gold</option>
                                    <option value="916">916 - 22K Gold</option>
                                    <option value="900">900 - 21.6K Gold</option>
                                    <option value="833">833 - 20K Gold</option>
                                    <option value="750">750 - 18K Gold</option>
                                    <option value="585">585 - 14K Gold</option>
                                    <option value="417">417 - 10K Gold</option>
                                    <option value="333">333 - 8K Gold</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label for="silverWeight" class="block text-gray-700">Weight (grams):</label>
                                <input type="number" v-model="goldWeight" class="w-full px-3 py-2 border rounded" required />
                            </div>

                            <div class="mt-8 p-6 bg-white rounded-2xl shadow-sm ring-1 ring-gray-200" v-if="goldCalculatedPriceOutput > 0">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">

                                    <!-- Gold Value -->
                                    <div>
                                        <p class="text-sm font-medium text-gray-500 uppercase tracking-wide mb-1">Gold Value</p>
                                        <p class="text-2xl font-bold text-gray-800">
                                            {{ formatCurrency(goldCalculatedPriceOutput) }}
                                        </p>
                                    </div>

                                    <!-- Total Gold Content -->
                                    <div>
                                        <p class="text-sm font-medium text-gray-500 uppercase tracking-wide mb-1">Total Gold Content</p>
                                        <p class="text-2xl font-bold text-gray-800">
                                            {{ goldTotalContent }}g
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </template>
                        <template v-if="selectedMetal === 'silver'">
                            <h2 class="mb-4 font-bold">Silver karat calculator</h2>

                            <div class="mb-4">
                                <label for="goldPrice" class="block text-gray-700 mb-1">Price (kg):</label>
                                <div class="flex items-center gap-2">
                                    <input
                                        id="silverPrice"
                                        type="number"
                                        v-model.number="silverPrice"
                                        class="flex-1 px-3 py-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                        required
                                    />

                                    <!-- Tooltip Wrapper -->
                                    <div class="relative group">
                                        <button
                                            type="button"
                                            @click="loadCurrentMetalPrice('XAG')"
                                            class="p-2 rounded-md border bg-gray-100 text-gray-600 hover:bg-gray-200 transition"
                                            aria-label="Reset to default spot price"
                                        >
                                            <!-- SVG Icon -->
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-4 w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                            >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v6h6M20 20v-6h-6M4 10a9 9 0 0115.9-2.6M20 14a9 9 0 01-15.9 2.6" />
                                            </svg>
                                        </button>

                                        <!-- Tooltip -->
                                        <div class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 w-max bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition pointer-events-none z-10">
                                            Reset to current spot price
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-4">
                                <label for="silverKarat" class="block text-gray-700">Karat:</label>

                                <select v-model="silverKarat" class="w-full px-3 py-2 border rounded" required>
                                    <option value="1000">1000</option>
                                    <option value="999">999</option>
                                    <option value="925">925 (Sterling)</option>
                                    <option value="835">835</option>
                                    <option value="800">800</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label for="silverWeight" class="block text-gray-700">Weight (grams):</label>
                                <input type="number" v-model="silverWeight" class="w-full px-3 py-2 border rounded" required />
                            </div>

                            <div class="mt-8 p-6 bg-white rounded-2xl shadow-sm ring-1 ring-gray-200" v-if="silverCalculatedPriceOutput > 0">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">

                                    <!-- Silver Value -->
                                    <div>
                                        <p class="text-sm font-medium text-gray-500 uppercase tracking-wide mb-1">Silver Value</p>
                                        <p class="text-2xl font-bold text-gray-800">
                                            {{ formatCurrency(silverCalculatedPriceOutput) }}
                                        </p>
                                    </div>

                                    <!-- Total Silver Content -->
                                    <div>
                                        <p class="text-sm font-medium text-gray-500 uppercase tracking-wide mb-1">Total Silver Content</p>
                                        <p class="text-2xl font-bold text-gray-800">
                                            {{ silverTotalContent }}g
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap lg:flex-nowrap">
                <div class="w-full bg-white p-6 shadow-md rounded-lg mt-6">
                    <div>
                        <h2 class="text-2xl font-bold mb-4 text-gray-900">How does the precious metal karat price calculator work?</h2>

                        <p class=" mb-6">
                            Let's walk through an example of how the precious metal karat price calculator works, using euros (€) as the currency.
                        </p>

                        <h2 class="text-xl font-semibold text-gray-700 mb-4">Example in Euros:</h2>

                        <ul class="list-disc pl-6 mb-4">
                            <li><strong>Item weight:</strong> 10 grams</li>
                            <li><strong>Karat:</strong> 18k (75% pure gold)</li>
                            <li><strong>Market price of gold:</strong> €55 per gram</li>
                        </ul>

                        <h3 class="text-lg font-semibold text-gray-700 mb-2 mt-6">Step-by-Step Calculation:</h3>

                        <div class="mb-4">
                            <h4 class="font-semibold text-gray-600">1. Determine the pure gold content:</h4>
                            <p class="text-base text-gray-800 mb-2">
                                Since the item is 18k, it means that 75% of the weight is pure gold (because 18k out of 24k is 75%).
                            </p>
                            <p class="text-sm text-gray-600">
                                <code class="bg-gray-200 p-1 rounded">Pure gold content = Weight of the item × (Karat / 24)</code>
                            </p>
                            <p class="text-sm text-gray-800 mt-2">
                                Pure gold content = 10 grams × (18 / 24) = 10 × 0.75 = 7.5 grams of pure gold
                            </p>
                        </div>

                        <div class="mb-4">
                            <h4 class="font-semibold text-gray-600">2. Calculate the value of the pure gold:</h4>
                            <p class="text-base text-gray-800 mb-2">
                                Now, we multiply the pure gold content (7.5 grams) by the current market price per gram (€55):
                            </p>
                            <p class="text-sm text-gray-600">
                                <code class="bg-gray-200 p-1 rounded">Value of the pure gold = Pure gold content × Market price per gram</code>
                            </p>
                            <p class="text-sm text-gray-800 mt-2">
                                Value of the pure gold = 7.5 grams × 55 €/gram = 412.50 €
                            </p>
                        </div>

                        <h3 class="text-xl font-semibold text-gray-700 mb-2">Result:</h3>
                        <p class="text-md text-gray-800">
                            So, in this example the value of the 10-gram 18k gold item would be <strong class="text-gray-900">€412.50</strong>.
                        </p>

                        <p class="mt-6 text-gray-800">
                            This is how the precious metal karat price calculator works. It helps determine the value of a piece of precious metal based on its weight, purity (karat), and the current market price of the metal.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from "axios";

export default {
    name: 'MetalCalculator',
    components: {
    },
    data() {
        return {
            selectedMetal: 'gold',

            // Metal data
            metalInformation: [],

            goldData: {},
            goldPrice: 0,
            goldDateTime: '',

            silverData: {},
            silverPrice: 0,
            silverDateTime: '',

            // Calculator data
            goldWeight: 0,
            goldKarat: 750,
            goldTotalContent: 0,
            goldCalculatedPriceOutput: 0,

            silverWeight: 0,
            silverTotalContent: 0,
            silverKarat: 925,
            silverCalculatedPriceOutput: 0,

        };
    },
    mounted() {
        this.loadInputs();
        this.loadCurrentMetalPrice('XAU');
        this.loadCurrentMetalPrice('XAG');
    },
    watch: {
        goldPrice: 'saveInputsDebounced',
        goldWeight: 'saveInputsDebounced',
        goldKarat: 'saveInputsDebounced',
        silverPrice: 'saveInputsDebounced',
        silverWeight: 'saveInputsDebounced',
        silverKarat: 'saveInputsDebounced',
    },
    methods: {
        saveInputs() {
            const inputs = {
                selectedMetal: this.selectedMetal,

                goldPrice: this.goldPrice,
                goldWeight: this.goldWeight,
                goldKarat: this.goldKarat,

                silverPrice: this.silverPrice,
                silverWeight: this.silverWeight,
                silverKarat: this.silverKarat,
            };
            localStorage.setItem('metalCalculatorInputs', JSON.stringify(inputs));
        },
        saveInputsDebounced: _.debounce(function() {
            this.saveInputs();
            this.calculatePrices();
        }, 300),
        loadInputs() {
            const savedInputs = localStorage.getItem('metalCalculatorInputs');
            if (savedInputs) {
                const inputs = JSON.parse(savedInputs);
                this.selectedMetal = inputs.selectedMetal;

                this.goldPrice = inputs.goldPrice;
                this.goldWeight = inputs.goldWeight;
                this.goldKarat = inputs.goldKarat;

                this.silverPrice = inputs.silverPrice;
                this.silverWeight = inputs.silverWeight;
                this.silverKarat = inputs.silverKarat;
            }
        },
        async loadCurrentMetalPrice(type) {
            const troyOunceToGramModifier = 32.1507;
            try {
                const dataResponse = await axios.get('https://www.quotez.nl/api/data/' + type);

                let avgPrice = (dataResponse.data.data[0]?.ask + dataResponse.data.data[0]?.bid) / 2;

                this.metalInformation[type] = {
                    price: (avgPrice * troyOunceToGramModifier),
                    priceOz: (avgPrice),
                    ts: dataResponse.data.ts ?? 'N.A.'
                };

                if(type === 'XAU') {
                    this.goldPrice = this.metalInformation['XAU']?.price.toFixed(2)
                }
                if(type === 'XAG') {
                    this.silverPrice = this.metalInformation['XAG']?.price.toFixed(2)
                }
            } catch (error) {
                console.error(error);
            }
        },
        setMetal(metal) {
            this.selectedMetal = metal;
            this.saveInputsDebounced();
        },
        calculatePrices() {
            this.goldCalculatedPriceOutput = ((this.goldKarat / 1000) * (this.goldPrice / 1000)) * this.goldWeight;
            this.silverCalculatedPriceOutput = ((this.silverKarat / 1000) * (this.silverPrice / 1000)) * this.silverWeight;

            this.goldTotalContent = ((this.goldKarat / 1000) * this.goldWeight).toFixed(2);
            this.silverTotalContent = ((this.silverKarat / 1000) * this.silverWeight).toFixed(2);
        },
        formatCurrency(value) {
            if(isNaN(value)) {
                return '-';
            }
            return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
        },
    },
};
</script>

