<template>
    <div class="p-6 max-w-7xl mx-auto">
        <h1 class="text-3xl font-bold mb-8">Finance Articles</h1>

        <div v-for="(posts, category) in groupedPosts" :key="category" class="mb-10">
            <h2 class="text-2xl font-semibold mb-4 border-b pb-2">{{ category }}</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div v-for="post in posts" :key="post.id" class="bg-white rounded-2xl shadow hover:shadow-md overflow-hidden transition">
                    <router-link :to="post.link">
                        <img v-if="post.image !== ''" :src="post.image" alt="" class="w-full h-48 object-cover">
                        <div class="p-4">
                            <span class="text-sm text-gray-500 uppercase">{{ post.category }}</span>
                            <h3 class="text-xl font-semibold mt-2 mb-1">{{ post.title }}</h3>
                            <p class="text-gray-600 text-sm">{{ post.description }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleOverview',
    data() {
        return {
            posts: [
                {
                    title: 'What is Compound Interest?',
                    category: 'Investing',
                    description: 'Discover how compound interest can grow your wealth exponentially.',
                    image: 'https://www.quotez.nl/img/micheile-henderson-ZVprbBmT8QA-unsplash.9cc656da.jpg',
                    link: '/article/compound-interest',
                },
                // {
                //     title: 'Understanding Stock Markets',
                //     category: 'Investing',
                //     description: 'A beginner’s guide to how stock markets work and how to invest smartly.',
                //     image: 'https://source.unsplash.com/600x400/?finance,stocks',
                //     url: '',
                // },
                //
                // {
                //     title: 'Debt Management Strategies',
                //     category: 'Personal Finance',
                //     description: 'Tips and tricks to manage and reduce your debt effectively.',
                //     image: 'https://source.unsplash.com/600x400/?debt,credit',
                // },
            ],
        }
    },
    computed: {
        groupedPosts() {
            return this.posts.reduce((groups, post) => {
                if (!groups[post.category]) {
                    groups[post.category] = []
                }
                groups[post.category].push(post)
                return groups
            }, {})
        },
    },
}
</script>

<style scoped>
body {
    background-color: #f9fafb;
}
</style>
