<template>
    <div class="min-h-screen flex flex-col bg-gray-100">
        <!-- Menu -->
        <nav class="bg-gray-800 p-4">
            <div class="container mx-auto flex justify-between items-center">
                <router-link to="/" class="text-white font-bold">Quotez</router-link>
                <div class="hidden md:flex space-x-4">
                    <router-link to="/" class="text-gray-300 hover:text-white px-3">Investment Growth</router-link>
                    <router-link to="/calculator/karat" class="text-gray-300 hover:text-white px-3">Karat Calculator</router-link>
                </div>
                <!-- Mobile Menu Button -->
                <button @click="toggleMenu" class="md:hidden text-white focus:outline-none">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
            <!-- Mobile Menu -->
            <div v-show="isOpen" class="md:hidden mt-2 space-y-2 bg-gray-700 p-4">
                <router-link to="/" class="block text-gray-300 hover:text-white">Investment Growth</router-link>
                <router-link to="/calculator/karat" class="block text-gray-300 hover:text-white">Karat Calculator</router-link>
            </div>
        </nav>

        <!-- Content -->
        <main class="flex-grow">
            <div class="content-around">
                <HomePage v-if="page === 'home'" />
                <InvestmentGrowth v-if="page === 'investment-growth'" />
                <MetalCalculator v-if="page === 'karat-calculator'" />
                <AboutPage v-if="page === 'about'" />
                <TickerDetails v-if="page === 'ticker-details'" />
                <PageNotFound v-if="page === '404'" />

                <PrivacyPage v-if="page === 'privacy'" />
                <TermsPage v-if="page === 'terms'" />

                <ArticleOverview v-if="page === 'article-overview'" />
                <CompoundInterest v-if="page === 'article-compound-interest'" />
            </div>
        </main>

        <!-- Footer -->
        <footer class="bg-gray-800 text-white mt-12">
            <div class="max-w-7xl mx-auto px-4 py-12 grid grid-cols-1 md:grid-cols-6 gap-8">

                <!-- About (takes up 3 columns) -->
                <div class="md:col-span-3">
                    <h5 class="text-lg font-bold mb-3">About Quotez</h5>
                    <p class="text-sm text-gray-300">
                        At Quotez, we offer comprehensive investment backtesting solutions designed to help you make informed decisions and optimize your portfolio. Explore our services today and take the first step towards smarter investing.
                    </p>
                </div>

                <!-- Spacer (optional) -->
                <div class="hidden md:block"></div>

                <!-- Calculators -->
                <div>
                    <h5 class="text-lg font-bold mb-3">Calculators</h5>
                    <ul class="space-y-2 text-sm text-gray-300">
                        <li><router-link to="/" class="hover:underline">Investment Growth</router-link></li>
                        <li><router-link to="/calculator/karat" class="hover:underline">Karat calculator</router-link></li>
                    </ul>
                </div>

                <!-- More -->
                <div>
                    <h5 class="text-lg font-bold mb-3">More</h5>
                    <ul class="space-y-2 text-sm text-gray-300">
                        <li><router-link to="/articles" class="hover:underline">Finance Articles</router-link></li>
                        <li><router-link to="/about" class="hover:underline">About</router-link></li>
                    </ul>
                </div>

            </div>

            <!-- Footer Bottom -->
            <div class="bg-gray-900 py-4">
                <div class="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="text-center md:text-left">
                        <p class="text-sm">&copy; {{ currentYear }} Quotez. All rights reserved.</p>
                    </div>
                    <div class="text-center md:text-right text-sm">
                        <router-link to="/privacy-statement" class="hover:underline me-4">Privacy Statement</router-link>
                        <router-link to="/terms-of-use" class="hover:underline me-4">Terms of use</router-link>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
import HomePage from '../components/HomePage.vue';
import InvestmentGrowth from '../components/InvestmentGrowth.vue';
import MetalCalculator from '../components/MetalCalculator.vue';
import TickerDetails from '../components/TickerDetails.vue';
import AboutPage from '../components/AboutPage.vue';

import PageNotFound from '../components/PageNotFound.vue';
import PrivacyPage from '../components/base-pages/PrivacyPage.vue';
import TermsPage from "@/components/base-pages/TermsPage.vue";
import CompoundInterest from "@/components/articles/CompoundInterest.vue";
import ArticleOverview from "@/components/articles/ArticleOverview.vue";

export default {
    name: 'DefaultTemplate',
    components: {
        ArticleOverview,
        CompoundInterest,
        InvestmentGrowth,
        MetalCalculator,
        TickerDetails,
        HomePage,
        AboutPage,
        PageNotFound,
        PrivacyPage,
        TermsPage,
    },
    props: {
        page: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            isOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen;
        }
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
footer {
    background-color: #1a202c;
    color: #fff;
}
</style>
