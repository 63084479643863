import {createApp} from 'vue'
import App from './App.vue'
import './tailwind.css'
import VueGtm from 'vue-gtm'

import {createRouter, createWebHistory} from 'vue-router'
import LayoutView from './layouts/DefaultTemplate.vue'

const routes = [
    // Calculators
    {
        path: '/',
        name: 'Investment Growth',
        component: LayoutView,
        props: {page: 'investment-growth'},
        meta: {
            title: 'Portfolio and savings growth calculator | Quotez',
            description: 'Calculate and track your savings and investment goals with ease. Quotez\'s Portfolio and Savings Calculator offers tailored insights to help you maximize your returns. Start planning for a secure future today!'
        }
    },
    {
        path: '/calculator/karat',
        name: 'Karat Gold Calculator',
        component: LayoutView,
        props: {page: 'karat-calculator'},
        meta: {
            title: 'Calculate precious metal prices | Quotez',
            description: 'Calculate precious metal prices based on the karats and the prevailing rate.'
        }
    },

    // Articles
    {
        path: '/articles',
        name: 'Finance Articles',
        component: LayoutView,
        props: { page: 'article-overview' },
        meta: {
            title: 'Finance Articles | Quotez',
            description: 'Explore our curated collection of finance articles covering investing, personal finance, budgeting, and more. Stay informed and grow your financial knowledge.'
        }
    },
    {
        path: '/article/compound-interest',
        name: 'Compound Interest',
        component: LayoutView,
        props: { page: 'article-compound-interest' },
        meta: {
            title: 'What is Compound Interest? | Quotez',
            description: 'Understand the power of compound interest with real-world examples and our interactive calculator. Learn how your money grows over time and why starting early makes all the difference.'
        }
    },

    // General pages
    {
        path: '/about',
        name: 'About',
        component: LayoutView,
        props: {page: 'about'},
        meta: {
            title: 'About us | Quotez',
            description: 'Learn more about Quotez, our mission, and the team dedicated to empowering you with tools for smarter financial planning. Discover our commitment to transparency, innovation, and customer-focused solutions that help you achieve your financial goals.'
        }
    },
    {
        path: '/privacy-statement',
        name: 'Privacy Statement',
        component: LayoutView,
        props: {page: 'privacy'},
        meta: {title: 'Privacy Statement | Quotez'}
    },
    {
        path: '/terms-of-use',
        name: 'Terms of use',
        component: LayoutView,
        props: {page: 'terms'},
        meta: {title: 'Terms of use | Quotez'}
    },
    {
        path: '/:catchAll(.*)',
        name: 'Page not found',
        component: LayoutView,
        props: {page: '404'},
        meta: {
            title: '404 - Page not found | Quotez',
            description: 'Calculate and track your savings and investment goals with ease. Quotez\'s Portfolio and Savings Calculator offers tailored insights to help you maximize your returns. Start planning for a secure future today!'
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App);

app.use(router);

app.use(VueGtm, {
    id: 'GTM-K3RW7WR9', // Replace with your GTM ID
    vueRouter: router, // Automatically tracks route changes
    enabled: true,     // Set to false to disable
    debug: false,      // Enable if you want console logs
})

app.mount('#app');