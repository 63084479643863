<template>
    <div class="max-w-4xl mx-auto px-4 py-8">
        <div class="bg-white shadow-lg rounded-2xl p-6 md:p-10 space-y-6">
            <h1 class="text-3xl font-bold text-gray-800">Privacy Statement – quotez.nl</h1>
            <p class="text-sm text-gray-500">Effective Date: <span class="font-medium">March 2025</span></p>

            <section>
                <h2 class="text-xl font-semibold text-gray-700 mb-2">1. No Personal Data Storage</h2>
                <p class="text-gray-600">
                    We do <strong>not</strong> store any personal information on our servers. Any data you enter into our calculators or tools is
                    <strong>not transmitted to us</strong> in any way.
                </p>
            </section>

            <section>
                <h2 class="text-xl font-semibold text-gray-700 mb-2">2. Local Session Storage</h2>
                <p class="text-gray-600">
                    All information you enter into calculators on quotez.nl is temporarily stored <strong>only in your browser’s session storage</strong>.
                    This means:
                </p>
                <ul class="list-disc list-inside text-gray-600 mt-2 space-y-1">
                    <li>The data <strong>never leaves your device</strong>.</li>
                    <li>It is <strong>automatically cleared</strong> when you close your browser tab or window.</li>
                    <li>We <strong>cannot access</strong> or retrieve this data in any form.</li>
                </ul>
            </section>

            <section>
                <h2 class="text-xl font-semibold text-gray-700 mb-2">3. Cookies and Analytics</h2>
                <p class="text-gray-600">
                    We may use cookies or third-party tools like Google Analytics to understand how visitors use our website.
                    These tools help us improve user experience but <strong>do not collect personal data</strong> from form inputs.
                </p>
                <p class="text-gray-600 mt-2">
                    You can manage or disable cookies in your browser settings at any time.
                </p>
            </section>

            <section>
                <h2 class="text-xl font-semibold text-gray-700 mb-2">4. Third-Party Links</h2>
                <p class="text-gray-600">
                    Our website may contain links to external websites. We are not responsible for the privacy practices of those websites.
                    Please review their privacy policies separately.
                </p>
            </section>

            <section>
                <h2 class="text-xl font-semibold text-gray-700 mb-2">5. Your Rights</h2>
                <p class="text-gray-600">
                    Since we do not collect or store personal data, there’s no user data to modify or delete.
                    If you have any concerns, feel free to reach out using the contact information below.
                </p>
            </section>

            <section>
                <h2 class="text-xl font-semibold text-gray-700 mb-2">6. Contact</h2>
                <p class="text-gray-600">
                    If you have any questions about this privacy statement, you can contact us at:
                </p>
                <p class="text-gray-600 mt-2">
                    📧 <strong>support@quotez.nl</strong>
                </p>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrivacyPage",
}
</script>